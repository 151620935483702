@import "https://fonts.googleapis.com/css2?family=Inter&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz@0,9..40;1,9..40&display=swap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,700;1,9..40,700&display=swap";
*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after, :before {
  --tw-content: "";
}

:host, html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Inter, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: initial;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: initial;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: initial;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre, fieldset {
  margin: 0;
}

fieldset, legend {
  padding: 0;
}

menu, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

[role="button"], button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio, canvas, embed, iframe, img, object, svg, video {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --text-inverse: #f9fafb;
  --text-foreground: #030712;
  --text-foreground-muted: #4b5563;
  --text-error: #e11d48;
  --text-primary: #4f46e5;
  --text-success: #65a30d;
  --text-warning: #ea580c;
  --text-disabled: #9ca3af;
  --bg-default: #f9fafb;
  --bg-default-hover: #e5e7eb;
  --bg-default-active: #d1d5db;
  --bg-alternate: #e5e7eb;
  --bg-alternate-hover: #d1d5db;
  --bg-alternate-active: #9ca3af;
  --bg-inverse: #f3f4f6;
  --bg-inverse-hover: #e5e7eb;
  --bg-inverse-active: #d1d5db;
  --bg-primary: #4f46e5;
  --bg-primary-hover: #4338ca;
  --bg-primary-active: #3730a3;
  --bg-secondary: #e2e8f0;
  --bg-secondary-hover: #cbd5e1;
  --bg-secondary-active: #94a3b8;
  --bg-error: #e11d48;
  --bg-warning: #ea580c;
  --bg-success: #bef264;
}

*, ::backdrop, :after, :before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-bottom-0 {
  bottom: 0;
}

.-bottom-0\.5 {
  bottom: -.125rem;
}

.-right-0 {
  right: 0;
}

.-right-0\.5 {
  right: -.125rem;
}

.left-0 {
  left: 0;
}

.left-2\/4 {
  left: 50%;
}

.left-4 {
  left: 1rem;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-1\/2, .top-2\/4 {
  top: 50%;
}

.z-10 {
  z-index: 10;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.box-border {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.h-\[15px\] {
  height: 15px;
}

.h-full {
  height: 100%;
}

.max-h-80 {
  max-height: 20rem;
}

.w-12 {
  width: 3rem;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.w-\[15px\] {
  width: 15px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[500px\] {
  width: 500px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2, .-translate-y-2\/4 {
  --tw-translate-y: -50%;
}

.-translate-y-1\/2, .-translate-y-2\/4, .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#eef0f3\] {
  --tw-border-opacity: 1;
  border-color: rgb(238 240 243 / var(--tw-border-opacity));
}

.border-\[none\] {
  border-color: none;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-t-blue-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-alternate {
  background-color: var(--bg-alternate);
}

.bg-default {
  background-color: var(--bg-default);
}

.bg-error {
  background-color: var(--bg-error);
}

.bg-inverse {
  background-color: var(--bg-inverse);
}

.bg-primary {
  background-color: var(--bg-primary);
}

.bg-secondary {
  background-color: var(--bg-secondary);
}

.bg-success {
  background-color: var(--bg-success);
}

.bg-transparent {
  background-color: initial;
}

.bg-warning {
  background-color: var(--bg-warning);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.fill-error {
  fill: var(--bg-error);
}

.fill-inverse {
  fill: var(--bg-inverse);
}

.fill-primary {
  fill: var(--bg-primary);
}

.fill-success {
  fill: var(--bg-success);
}

.fill-warning {
  fill: var(--bg-warning);
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.font-display {
  font-family: DM Sans, sans-serif;
}

.font-sans {
  font-family: Inter, sans-serif;
}

.text-\[2\.5rem\] {
  font-size: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-disabled {
  color: var(--text-disabled);
}

.text-error {
  color: var(--text-error);
}

.text-foreground {
  color: var(--text-foreground);
}

.text-foreground-muted {
  color: var(--text-foreground-muted);
}

.text-inverse {
  color: var(--text-inverse);
}

.text-primary {
  color: var(--text-primary);
}

.text-success {
  color: var(--text-success);
}

.text-warning {
  color: var(--text-warning);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.placeholder-\[\#5B616E\]::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(91 97 110 / var(--tw-placeholder-opacity));
}

.opacity-\[0\.38\] {
  opacity: .38;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.shadow, .shadow-\[0px_8px_12px_0px_\#5B616E1F\] {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_8px_12px_0px_\#5B616E1F\] {
  --tw-shadow: 0px 8px 12px 0px #5b616e1f;
  --tw-shadow-colored: 0px 8px 12px 0px var(--tw-shadow-color);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.ock-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #d1d5db #fff;
}

.hover\:bg-alternate-hover:hover {
  background-color: var(--bg-alternate-hover);
}

.hover\:bg-default-hover:hover {
  background-color: var(--bg-default-hover);
}

.hover\:bg-inverse-hover:hover {
  background-color: var(--bg-inverse-hover);
}

.hover\:bg-primary-hover:hover {
  background-color: var(--bg-primary-hover);
}

.hover\:bg-secondary-hover:hover {
  background-color: var(--bg-secondary-hover);
}

.active\:bg-alternate-active:active {
  background-color: var(--bg-alternate-active);
}

.active\:bg-default-active:active {
  background-color: var(--bg-default-active);
}

.active\:bg-inverse-active:active {
  background-color: var(--bg-inverse-active);
}

.active\:bg-primary-active:active {
  background-color: var(--bg-primary-active);
}

.active\:bg-secondary-active:active {
  background-color: var(--bg-secondary-active);
}

@font-face {
  font-family: CafeBabeFont;
  src: url("Coinbase_Sans-Regular-web-1.32.251a6f72.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

.cafebabe-title {
  font-family: CafeBabeFont, sans-serif;
}

.connected {
  height: 100vh;
}

.not-connected {
  height: 50vh;
}
/*# sourceMappingURL=index.aeeb84e6.css.map */
